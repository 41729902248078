
@import "_checkbox.scss";
@import "_forms.scss";
@import "_common.scss";

h2{
  font-family: Lato;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: $tablano_blue
}

.info_text{
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  text-align: center;
  color: $dark_grey;
}

.m_13_auto{
  margin: 13px auto;
}

.gtl_logo{
  margin: 260px auto 55px;
  text-align: center;
}

.form__login {
  margin: 0 auto;
  position: relative; 
  
}

  .login{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: $dark_grey;
    text-align: center;
    display: block;
    margin: 105px auto;
  }

  .error__message{
    position: absolute;
    right: 0;
    height: 30px;
    width: 180px;
    top: 15px;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-align: left;
    color: $red_error;
  }

  .domain-section{
    border-bottom:thin solid#ccc;
    padding-bottom: 30px;
    margin-top: 30px;
    .select_box{
      width: 100%;
      .selected_item_short{
        font-weight: 700;
      }
    }
  }



  /* Responsive: Portrait tablets and up */
@media screen and (max-width: 768px) {
    .gtl_logo{
      margin: 15px auto;
      text-align: center;
    }

    .input__decorator{
      margin: 0 auto;
      text-align: center;
    }
    .gtl_input,.btn-submit{
      width: 100%;
    }
    .input__decorator .check_box{
      position: relative;
      right: 0;
    }
    .input__decorator{
        .check_box{
          &::before{
            content: '';
          }
        }
    }
    .has-error .form-control,.input-validation-error{
        &:focus{
          border-color: $red_error;
          box-shadow: 0 0 0 0;
          color: $red_error;
        }
    }
    .has-success .form-control{
      &:focus{
        border-color: $green_correct;
        box-shadow: 0 0 0 0;
        color: $green_correct;
      }
    }
}