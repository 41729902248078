@import "constants";

html, body, p, span, h1, h2, h3, h4, button {
  font-family: $font-stack;
  font-size: 12px;
}

html, body, p, span, h1, h2, h3, h4, button, td, dd {
  color: $form_text_color;
}

.text--capital{
  text-transform: uppercase;
}

.text--emp {
  font-weight: 600;
}

.text--bold {
  font-weight: 700;
}

.text--caps {
  text-transform: uppercase;
}

.text--blue {
  color: #039eef;
}

.text--red,.text_red {
  color: $theme_color_14;
}

.text--theme-color {
  color: $theme_color;
}

.text--link{
  cursor: pointer;
}

.text--underline--focus{
  text-decoration: none;
  &:hover,&:focus{
    text-decoration: underline;
  }
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

dl {
  padding-top:$modal_padding;
  padding-bottom: $modal_padding;
}

dt {
  padding-bottom: ($modal_padding / 2);
  font-size: $text_size_tiny;
}

dd {
  font-size: $text_size_normal;
}

sup {
        font-size: 0.7em;
        vertical-align: super;
        position: relative;
        top: 0.05em;
        line-height: 0;
}

.rotate-right-90 {
  transform: rotate(90deg);
}

.rotate-left-90 {
  transform: rotate(-90deg);
}