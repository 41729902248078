@import "constants";
/*
	#Overlay
*/

/**
 * Overlay is kind of popup window present on the map, when user click on a object
 */
.overlay {
  position: relative;
  top: -36px;
  left: 3px;
}

.overlay__body {
  width: 380px;
  height: 215px;
  position: relative;
  z-index: 2;
  background-color: $overlay_body_background_color;
}

.overlay__cluster__body {
  width: max-content;
  // min-height: 70px;
  position: absolute;
  z-index: 2;
  background-color: $overlay_body_background_color;
  top: 50%;
  left: 100%;
  margin-left: 35px;
}

.overlay--shadow {
  box-shadow: 0 0 15px #1f3845;
}

.overlay__wrapper {
  height: 100%;
}

.overlay__arrow {
  position: absolute;
  left: 47%;
  bottom: -6px;
  width: 20px;
  height: 20px;
  background-color: $overlay_arrow_background_color;
  transform: rotate(45deg);
  z-index: 1;
}

.overlay__cluster__arrow {
  top:0;
  margin-top: 25px;
  margin-left: 25px;
}

.overlay__content {
  width: 100%;
  height:100%;
  display: flex;
  flex-direction: column;
}

.overlay__content__header {
  width: 100%;
  height: 55px;
  background-color: $overlay_header_background_color;
  color: #fff;
}

.overlay__content__header__text {
  padding: 12px 15px;
  font-size: 1.4em;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.overlay__content__header__icon {
  width: 25px;
  background-color: $overlay_header_background_color;
  margin-left: 20px;
}

.overlay__content__content {
  width: 100%;
  background-color: $overlay_content_background_color;
  flex-grow: 1;
  padding: 20px 10px;
}

.cluster__feature__list{
  background-color: $overlay_footer_background_color;
  box-shadow: 5px 0px 10px $theme_color_11;
  ul{
    min-height: 70px;
    .ajax-loader {
      position: absolute;
      width: 60px;
      height: 60px;
      top: -15px;
      left: 10px;
    }
  }
}

.cluster__feature__row{
  border-bottom: thin solid $theme_color_11;
  font-size: 13px;
  line-height: 21px;
  padding: 5px;
  &:hover{
    background-color: $theme_color_3
  }
 a{
    text-decoration: none;
    color: $theme_color_dark
  }
  &:last-child {
    border-bottom-width: 0;
  }
}

.overlay__content__content__text {
  font-size: $text_size_big+1;
  font-weight: 600;
  color: $theme_color_9;
  padding-bottom: 5px;
  text-overflow:ellipsis;
  overflow:hidden;
  white-space: nowrap;
}

.overlay__content__content__title {
  font-size: 0.9em;
  color: $theme_color;
  padding-bottom: 5px;
  padding-top: 10px;
  font-weight: 600;
}

.overlay__content__content__logo {
  width: 100%;
}

.overlay__content__content__logo__padded {
  width: 100%;
  padding-left: 15px;
}

.overlay__content__footer {
  background-color: $overlay_footer_background_color;
  width: 100%;
  height: 50px;
  padding: 5px 10px;

}

.overlay__button {
  background-color: transparent;
  border: none;
  color: $theme_text_color_0;
  font-weight: 600;
  padding: 18px 17px;
  @extend .text--capital;
}

.overlay-button-submit{
  padding: $modal_padding $modal_padding+2;
  background-color: $theme_color;
  color:$theme_text_color_0;
  font-weight: 700;
  font-size: $text_size_small+2;
  height: 31px;
  margin-top: 3px;
  //  width: $button_width;
  &:hover{
    background-color: $theme_color_dark
  }
}