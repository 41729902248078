@mixin floatElement($position){
  @if $position == center {
    margin: 0 auto;
  } @else {
    float: $position;
  }
}

@mixin radius($radius){
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin placeholder-color($color) {
  &::-webkit-input-placeholder { /* WebKit browsers */
      color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: $color;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: $color;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10+ */
      color: $color;
  }
    
}