.gtl_input{
      width: 100%;
      height: 40px;
      border-radius: 5px;
      background-color: $color-white;
      border: solid 1px $light_grey_input_field_inactive;
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      text-align: left;
      color: $dark_grey;
      margin: 5px auto;
      padding: 10px 20px;
      &:focus{
        color: $dark_grey;
        border: solid 1px $dark_grey;
        box-shadow: 0 0 0 0;
        @include placeholder-color($dark_grey);
      }
      &.error{
        color:$red_error;
        border-color: $red_error; 
      }
      @include placeholder-color($light_grey_input_field_inactive);
      &.ok{
          border-color: $green_correct;
          color: $green_correct;
      }
      &.ko{
          border-color: $red_error;
          color: $red_error;
      }
  }
  .checkbox{
    margin: 16px auto;
    text-align: center;
    label{
      font-family: 'Lato', sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      color: $dark_grey;
    }
  }
  .btn-submit{
    width: 100%;
    height: 40px;
    border-radius: 3px;
    border: 0;
    background-color: $tablano_blue;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    color: $color-white;
    margin: 12px auto;
    padding: 12px 14px;
    &[disabled]{
        background-color: $button_inactive_grey;
        &:hover{
            background-color: $button_inactive_grey;
        }
    }
  }

  .input__decorator{
        position: relative;
        .check_box{
            position: absolute;
            top: 0;
            right: -210px;
            height: 40px;
            width: 200px;
            font-family: Lato;
            font-size: 12px;
            font-weight: 500;
            text-align: left;
            &:empty{
                display: none;
            }
            &:before{
                content: "\f00d";
                font-family: FontAwesome;
                display: block;
                margin: 10px;
                float: left;
            }
            &.check_ok{
                color: $green_correct;
            }
            &.check_ko{
                color: $red_error;
            }
        }
  }
  .has-error .form-control,.input-validation-error{
      border-color: $red_error;
      box-shadow: 0 0 0 0;
      color: $red_error;
        &:focus{
          border-color: $red_error;
          box-shadow: 0 0 0 0;
          color: $red_error;
        }
  }
  .has-success .form-control{
      border-color: $green_correct;
      box-shadow: 0 0 0 0;
      color: $green_correct;
        &:focus{
          border-color: $green_correct;
          box-shadow: 0 0 0 0;
          color: $green_correct;
        }
  }

  .domain-form{
    .action-button{
        border-radius: 5px;
        margin-top: 24px;
        font-weight: 300;
        font-size: 14px;
        height: 37px;
        display: inline-block;
        padding-top: 8px;
        font-weight: 500;
      }      
    & .form__label{
      padding-top: 10px;
      padding-bottom: 5px;
      text-transform: none; 
    }
    input{
      padding-left: 18px;
      padding-right: 18px;
      border-radius: 5px;
      &.editable-col{
        background-color: #F6F7F9;
      }
    }
    table{
      
      tr.feature-list__row{
        &:hover{
          background-color: white
        }
      }
      td{
        font-size: 14px;
        &.feature-list__cell{
          border:0 !important;
        }
        .editable-col{
          font-weight: 700;
          color: #203746;        
        }
      }
    }
  }