/*
  Fonts
*/

@font-face {
  font-family: OpenSans;
  src: url(../fonts/open_sans/OpenSans-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: OpenSans;
  src: url(../fonts/open_sans/OpenSans-Bold.ttf);
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: OpenSans;
  src: url(../fonts/open_sans/OpenSans-BoldItalic.ttf);
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: OpenSans;
  src: url(../fonts/open_sans/OpenSans-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: OpenSans;
  src: url(../fonts/open_sans/OpenSans-Italic.ttf);
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: OpenSans;
  src: url(../fonts/open_sans/OpenSans-ExtraBoldItalic.ttf);
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: OpenSans;
  src: url(../fonts/open_sans/OpenSans-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: OpenSans;
  src: url(../fonts/open_sans/OpenSans-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: OpenSans;
  src: url(../fonts/open_sans/OpenSans-Semibold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: OpenSans;
  src: url(../fonts/open_sans/OpenSans-SemiboldItalic.ttf);
  font-weight: 600;
  font-style: italic;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Lato/S6uyw4BMUTPHjxAwXjeu.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Lato/S6uyw4BMUTPHjx4wXg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
