.auth {
  background-image: url("../img/login_background.jpg");
  background-size: 100%;

  button {
    background-color: $theme_color_4;
    color: $theme_color_0;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    width: $button_width+30px;
    height: 39px;
    align-self: center;
  }
}

.auth-wrap {
  width: auto;
}

.auth-logo-wrap{
  padding: 0 50px;
}

.auth-logo {
  padding-top: 15px;
  padding-bottom: 35px;
  width: 100%;
}

.auth-save-credentials {
  input {
    margin: 0;
    display: none;
  }
  input + .checkbox {
    width: 10px;
    height: 10px;
  }
  input + .checkbox::before,
  input:checked + .checkbox::before {
    content: '☐';
    font-size: 30px;
    position: absolute;
    top: -13px;
    left: -22px;
    color: $theme_color_4;
  }
  input:checked + .checkbox::before {
    content: '☑';
  }
  label, label span {
    color: $theme_color_4;
  }
}

.auth-form{
  padding-top: 15px;
  padding-bottom: 15px;
  width:$auth_width;
  margin: 0 auto;

  .input-group{
    display: inline-block;
  }

  .input-group-btn{
    position: absolute;
    right: $input_height;
    button{
      margin: 0;
      border: none;
      width: $input_width;
      height: $input_height;
      background-color: transparent;
      &.no-link{
       cursor: auto;
      }
      img{
        vertical-align: middle;
      }
    }
  }
  input {
    border-color: $theme_color_4;
    border-radius: 0px;
    color: $theme_color_4;
    -webkit-appearance: inherit;
    height:$input_height;
    padding: 0 40px 0 15px;
    font-size: $text_size_big+1;
    &::placeholder {
      color: $theme_color_3;
    }
    &.fail{
      border-color: $theme_color_16;
      color: $theme_text_color_2;
    }
  }

  input:first-child{
    margin-bottom: -1px;
  }


}

.error-msg{
  color: $theme_color_16;
  font-size: $text_size_big+1;
  line-height: 21px;
  text-align: center;
  strong{
    font-weight: 700;
  }
}