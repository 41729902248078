@import "constants";

/*
	#Modal
*/

.modal {
  display: flex !important;
  justify-content: center;
  vertical-align: middle;
  background-color: rgba(0,0,0,0.1);
}

.clickable{
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.modal-hide{
  display: none !important;
}

.modal-dialog {
  width: $modal_width;
  max-width: $modal_max_width;
  align-self: center;
  margin: inherit;
}

.modal-sm{
  width: $modal_width_sm;
  max-width: $modal_max_width_sm;
  & .modal-content{
    min-height: $modal_height_sm;
    height: auto;
    border-radius: $modal_button_radius;
  }
}

.modal-pbi{
  width: $modal_width_pbi;
  max-width: $modal_max_width_pbi;
  & .modal-content{
    height: 85vh;
    border-radius: $modal_button_radius;
  }
}

.modal-lg{
  width: $modal_width_lg;
  max-width: $modal_max_width_lg;
  & .modal-content{
    height: $modal_height;
    border-radius: $modal_button_radius;
  }
}

.modal-content {
  background-color: transparent;
  border: none;
  height: $modal_height;
  border-radius: $modal_corner_radius;
  &>div {
    min-height: 20vh  ;
    height: 100%;
  }
}

.modal-header {
  border-bottom: none;
}

.modal__content,
.modal__header,
.modal__footer {
  width: 100%;
}

.modal__content {
  border-bottom-left-radius: $modal_corner_radius;
  border-bottom-right-radius: $modal_corner_radius;
}

.modal__header {
  /*This is because from some reason in Safari it's not stretched */
  width:100%;
  height: $modal_header_height;
  background-color: $theme_color_dark;
  border-top-left-radius: $modal_corner_radius;
  border-top-right-radius: $modal_corner_radius;
  padding: 0;
  flex-shrink: 0;
  z-index: 2;
}
.modal__header_white{
  width:100%;
  height: $modal_header_height;
  background-color: $theme_color_0;
  border-top-left-radius: $modal_corner_radius;
  border-top-right-radius: $modal_corner_radius;
  padding: 0;
  flex-shrink: 0;
}

.search-list{
  color: #A0A0A0;
}

.modal_small {
  background-color: $theme_color_0;
}
.modal_inform-body{
  text-align: center;
  & span{
    font-size: $text_size_big+1;
    color: $theme_color_15;
    text-align: center;
    line-height: 18px;

  }
  & .form-content{
    margin-top: 15px;
  }
}


.modal__header,
.modal__header--left,
.modal__header--right {
  /* Text color: */
  color: #fff;
  input{
    color: $theme_color_9;
    width: 30%;
  }
}

.modal__header--left,
.modal__header--right{
  height: 100%;
}
.modal__header--left {
}

.modal__header--right {
  form{
    position: relative;
    right: 10px;
    top: 5px;
    display: inline-block;
    width: 83%;
    input{
      width: 100%;
    }
    .form-control-feedback{
      height: 34px;
    }
  }
}

.modal__header--livesearch{
  width: 100%;
  background-color: $pale-grey;
  & form{
    padding: 12px 55px;
    border: solid 1px #dbe4ea;
    border-left: 0;
    border-right: 0;
    margin: 0;
    & input{
      border-radius: 30px;
      background-color: #fdfeff;
      border: solid 1px #c2cfd8;
      width: 100%;
    }
  }
  .form-control-feedback{
    top:10px;
    right: 60px;
  }
}

.modal__header__wrapper>*{
  margin: 0 $modal_header_site_margin;
}

.modal__header__title {
//  margin: 0 15px;
  color: $theme_color_0;
  font-size: $text_size_big+5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  //when overflow-x applied the bottom of text is not visible, so I need to specify height;
  height: auto;
  padding: 0 5px;
  & .title_icon{
    vertical-align: middle;
    margin: 0 10px 0 0;
    width: 25px;
  }
}

.modal__header__title_no_icon{
  padding: 0 17px;
}

//.modal__header__wrapper [class*="ico-"]{
//  background-size: 15px;
//}

.modal__header__wrapper button,
.modal__header__wrapper .modal__header__action {
  color: #fff;
  font-size: $text_size_normal;
  font-weight: 700;
  height: 100%;
  float: right;
  display: inline-block;
}

.modal__header__wrapper button:hover,
.modal__header__wrapper .modal__header__action:hover {
  color: #949FA6;
}

.modal__header--l-border,
.modal__header--r-border {
  position: relative;
}


.modal__header--l-border::after,
.modal__header--r-border::after {
  content: "";
  height: 30px;
  border-left: 1px #748388 solid;
  position: absolute;
  top: 7px;
}

.modal__header--l-border::after {
  /* Needs to be in sync with .modal__header__wrapper>*
     Otherwise the division line will not be centered
    */
  left: -$modal_header_site_margin;
}

.modal__header--r-border::after {
  /* Needs to be in sync with .modal__header__wrapper>*
     Otherwise the division line will not be centered
    */
  right: -$modal_header_site_margin;
}

div.select_box{
  // font-style: italic;
  border-radius: 6px;
  background-color: #dee4e8;
  margin: 13px 0;
  font-size: $text_size_normal+1;
  font-weight: 400;
  min-width: 115px;
  width: auto;
  height: $form_element_height;
  float: right;
  position: relative;
  color: $dark-blue-grey;
  &:after{
    content: " ";
    position: absolute;
    background-image: url("../img/gtl/header_action_dropdown.png");
    width: 11px;
    height: 6px;
    top: 16px;
    right: 10px;
  }
  & .selected_item{
    padding: 10px 25px 10px 10px;
    cursor: pointer;
    width: 230px;
  }
  & .selected_item_short {
    padding: 10px 25px 10px 10px;
    cursor: pointer;
    width: auto;
  }
//    &.export:before{
//      content:url('../img/gtl/overlay_action_export-blue.png');
//      margin-right: 5px;
//    }
  ul{
    li{
      & > span{
        color: $greyblue;
        font-size: 14px;
        display: inline-block;
        width: 100%;
        }
      &.active_row{
        & > span{
          font-weight: 700;
          color: #039eef;
        }
      }
    }
  }
  ul.common_items{
    li{
      padding: 0;
      & .modal-button-submit{
        background-color: white;
        height: auto;
        color: $theme_color;
        padding: 0;
        &:hover{
          text-decoration: underline;
        }
      }

      & .export{
        padding: 10px;
        &:before{
          content:url('../img/gtl/overlay_action_export-blue.png');
          margin-right: 5px;
        }
      }
    }
  }
  ul.common_items{
    background-color: white;
    display: block;
    border: thin solid #aec1cf;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 8px;
    position: relative;
    z-index: 100;
    li{
      font-weight: 300;
      display: block;
//      white-space: pre;
      min-height: 1.2em;
      padding: 7px 0 7px 7px;
      color: #000000;
      &:hover,&:focus{
//        background-color: #039eef;
//        color: #ffffff;
        cursor: pointer;
      }
      &.selected_filter{
        border: thin solid $theme_color_8;
        border-radius: 2px;
        color: #eeeeee;
        background-color: #1ca8f1;
        margin: 0 1px;
        cursor: default;
      }
      span.remove_condition{
        background: transparent url("../img/gtl/alert-close.png") center center no-repeat;
        width: 10px;
        height: 10px;
        cursor: pointer;
        float: right;
        margin: 2px 2px;
      }
      span.checkbox-unchecked{
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: -1px 8px 0 0;
        vertical-align: middle;
        background: url("../img/gtl/overlay_checkbox_default-grey.png") left top no-repeat;
        cursor: pointer;
      }
      span.checkbox-checked{
        display: inline-block;
        width: 16px;
        height: 16px;
        margin: -1px 8px 0 0;
        vertical-align: middle;
        background: url("../img/gtl/overlay_checkbox_default-grey.png") right top no-repeat;
        cursor: pointer;
      }

          span.filter__dropdown{
            display: inline-block;
            width: 16px;
            height: 16px;
            margin: -1px 8px 0 0;
            vertical-align: middle;
            background: #85a6bd url(../img/gtl/header_action_dropdown-active-reverse.png) center no-repeat;
            cursor: pointer;
            border-radius: 1px;
          }
          span.filter__dropdown-active{
            display: inline-block;
            width: 16px;
            height: 16px;
            margin: -1px 8px 0 0;
            vertical-align: middle;
            background: #85a6bd url("../img/gtl/header_action_dropdown-active.png") center no-repeat;
            cursor: pointer;
            border-radius: 1px;
          }

    }
  }
}

.filter__task__sublist{
  margin-left: 23px;
}

// .active_filter{
//   background-color: #039eef !important;
//   color: #ffffff !important;
//   font-style: normal !important;
//   background-image: url("../img/gtl/header_action_dropdown-active.png") !important;
//   ul{
//     border-color: #aec1cf;
//   }
// }
.opened_filter{
    &:after{
    content: " ";
    position: absolute;
    background-image: url("../img/gtl/header_action_dropdown-active-reverse.png") !important;
    width: 11px;
    height: 6px;
    top: 16px;
    right: 10px;
  }
  background-color: #039eef !important;
  color: #ffffff !important;
  font-style: normal !important;
  ul{
    border-color: #aec1cf;
  }
}
.action-box{
  button{
    padding: 0;
    padding-left: 10px;
  }
}
.uberfalligt{
  color: #f1680c;
  font-weight: 700;
}
.falligkeit-date{
  width: 120px;
}
.modal-action-header{
//  border-bottom: 1px solid #D0D0D0;
  -webkit-box-shadow:0px 1px 3px #D0D0D0;
  -moz-box-shadow:0px 1px 3px #D0D0D0;
  box-shadow:0px 1px 3px #D0D0D0;
  // padding: 5px 0;
  color: $theme_color_dark;
//  position: fixed;
  width: 100%;
  background-color: $theme_color_0;
  z-index:1;
  // min-height: 100px;
  input{
    display: inline-block;
    vertical-align: middle;
    font-size: $text_size_normal+1;

    &.normal-text{
      border-radius: 6px;
      border: solid 1px #85a6bd;
      margin-left: 26px;
      margin-top: 9px;
      text-align: center;
      color: #039eef;
      font-weight: 700;
      width: 80%;
    }
    &.date-text{
      border-radius: 6px;
      border: solid 1px #85a6bd;
      margin: 9px 5px 0px 5px;
      text-align: center;
      color: #039eef;
      font-weight: 700;
      width: 40%;
      vertical-align: baseline;
    }
  }
  select{
    background-color: #dee4e8;
    font-style: italic;
    border-radius: 5px;
    background-image: url("../img/gtl/header_action_dropdown.png");
    margin: 6px 0;
    font-size: $text_size_normal+1;
    padding: 0 27px 0 17px;
    font-weight: 600;
    width: auto;
    option{
      background-color: #ffffff;
      color: #2a424e;
    }
    &::-ms-expand {
      display: none;
    }
    &.active{
      background-color: #1ca8f1;
      color: #ffffff;
      font-style: normal;
      background-image: url("../img/gtl/header_action_dropdown-active.png");
    }
  }
  & .custom-checkbox{
    padding: 0;
    width: auto;
    & > label{
      display: inline-block;
      margin: 22px 3px;
    }
  }
  & .select_box{
    float: none;
    display: inline-block;
    width:100%;
  }
  & .modal-action-row{
    height: 63px;
  }
}

.modal__header__action--l-border::after {
  content: "";
  height: 20px;
  border-left: 1px #c1c1c1 solid;
  margin: 0 10px 0 10px;
}
.modal__header__action--r-border::before {
  content: "";
  height: 20px;
  border-left: 1px #c1c1c1 solid;
  margin: 0 15px 0 10px;
  position: absolute;
  top: 65px;
}

.header__action-grey{
  color: #9aa6ae;
}
  .header__action-select-label{
    display: inline-block;
    padding: 20px 8px 0;
//    float: right;
    font-size: 14px;
    font-weight: 600;
    color: $theme_color_10;
    float: right;
  }
.modal__content {
  flex-grow: 2;
  /* We need to cover all modal window with table according design
      so I reset left and right padding and set up width to 100%
  */
  padding: 0;
  width: 100%;
}

.modal__footer {
  height: $modal_footer_height;
  background-color: $theme_color_1;
  text-align: right;
  padding: 0;
  border-top: none;

  border-bottom-left-radius: $modal_corner_radius;
  border-bottom-right-radius: $modal_corner_radius;
}

.modal-body {
  overflow: auto;
  background-color: $modal_background_color;
}

.div-body {
  overflow: auto;
}

.modal-button-submit{
  padding: $modal_padding 25px;
  background-color: $theme_color;
  color:$theme_text_color_0;
  font-weight: 700;
  font-size: $text_size_normal+1;
  height: $input_height;
//  width: $button_width;
  &:hover{
    background-color: $theme_color_dark
  }
  &.disabled:hover{
    background-color: #88e29f;
    cursor: default;
  }
  &:disabled{
    background-color: $theme_color_dark;
    cursor: default;
  }
}

.modal-radius{
  @include radius($modal_button_radius);
}

.default-border{
  border: 1px solid $theme_color_13;
}


.file-button{
  border-radius: $button_radius;
  margin: 13px 0;
  font-weight: 300;
  font-size: $text_size_normal+1;
  height: $form_element_height;
  display: inline-block;
  cursor: pointer;
  padding-top: 12px;
  font-style: italic;
  & span{
  color: $theme_text_color_0;
  }
  &.export:before{
    content:url('../img/gtl/overlay_action_export.png');
    margin-right: 5px;
  }
  &.import{
    color: $theme_text_color_0;
    & span{
      font-size: $text_size_normal+1;
    }
    &:before{
      content:url('../img/gtl/overlay_action_import.png');
      margin-right: 5px;
    }
  }
}

.red-button{
  background-color: #ee3535;
}
.green-button{
  background-color: #88e29f;
}
.blue-button{
  background-color: $theme_color;
}

.disabled{
  font-weight: 300;
  cursor: not-allowed;
}

.modal-conf{
  width: $modal_width_conf;
  max-width: $modal_max_width_sm;
  height: $modal_height_conf;
  max-height: $modal_max_height_conf;
  border: thin solid #cbcbcb;
  border-radius: $modal_corner_radius;
  & .modal-content{
    min-height: $modal_height_conf;
    height: auto;
    border-radius: $modal_button_radius;
    & > div{
      min-height: $modal_height_conf;
    }
  }
}

.confirm_modal{
  display: block;
  border-radius: $button_radius;
  & .form-content{
    padding: 28px;
    margin-top: 0;
  }
}

.confirm-button{
  border-radius: $button_radius;
  font-size: $text_size_normal+1;
  color: $theme_color_0;
  font-weight: 600;
  height: $form_element_height;
  width: $confirm_button_width;
  &:hover{
    background-color: $theme_color_17;
  }
}
.yes-button{
  background-color: $yes_button_background_color;
}
.no-button{
  background-color: $no_button_background_color;
}

.element-on-left{
  @include floatElement(left);
}

.element-on-right{
  @include floatElement(right);
  align-self: flex-end;
}

.element-on-center{
  display: block;
  @include floatElement(center);
}

.margin-20-15{
  margin:20px 15px;
}
.margin-top-8{
  margin-top:8px;
}
.margin-left-10{
  margin-left:8px;
}
.width-10vw{
  width: 10vw;
}
.font-normal{
 font-size: $text_size_normal+1;
}
.italic{
  font-style: italic;
}

.no-padding{
 padding: 0;
}

.no-left-padding{
  padding-left: 0 !important;
}

.modal__content--feature {
  height:100%;
}

/* tab set is using additional div with class='ng-isolate-scope' */
.modal__content--feature>div {
  display: flex;
  flex-direction: column;
}


.modal.fade {
  opacity: 1;
}

.modal.fade .modal-dialog, .modal.in .modal-dialog {
  transform: translate(0, 0);
}

.pagination {
  margin: 0;
  &>span {
    margin-left: 3px;
    margin-right: 3px;
    cursor: pointer;
    &.active{
      color: $theme_color;
      cursor: inherit;
    }
  }
}

.dringend{
  color: $theme_color_14;
  text-transform: uppercase;
  text-align: center;
  display: block;
  &:before{
    content: '';
    color: #ed1414;
    width: 13px;
    height: 13px;
    border: thin solid $theme_color_14;
    display: inline-block;
    background-color: $theme_color_14;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.map-catcher{
  color: $theme_color;
  &:before{
    content: url('../img/gtl/custom_cursor_small.png');
    margin-right: 10px;
    vertical-align: middle;
    height: 24px;
    display: inline-block;
  }
}
img.img_icon{
  width: 25px;
  margin-top: -31px;
  margin-left: 10px;
}
.inp-err {
  border: 1px red solid;
}