@import "constants";

.auth {
  background-color: $theme_color_0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}