
$grid_columns: 12;

.fl-container {
  display: flex;
}

.fl-container-col {
  @extend .fl-container;
  flex-direction: column;
  //grow/stretch...
  @for $i from 1 through $grid_columns {
    &>.fl-grow-#{$i} {
      flex-grow: $i;
      padding-top: 5px;
      padding-bottom: 5px;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }

    &>.fl-shrink-#{$i} {
      flex-shrink: $i;
    }
  }
}

.fl-container-row {
  @extend .fl-container;
  flex-direction: row;
  //grow/stretch...
  @for $i from 1 through $grid_columns {
    &>.fl-grow-#{$i} {
      flex-grow: $i;
      padding-left: 5px;
      padding-right: 5px;
      width: #{100 / ($grid_columns / $i)+'%'};
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    &>.fl-shrink-#{$i} {
      flex-shrink: $i;
    }
  }
}

.fl-justify-sb {
  justify-content: space-between;

  &>* {
    padding-left: 5px;
    padding-right: 5px;
  }
  &>*:first-child {
    padding-left: 0;
  }
  &>*:last-child {
    padding-left: 0;
  }
}

//alignment
.fl-justify-sa {
  justify-content: space-around;
}

.fl-justify-sb {
  justify-content: space-between;
}

.fl-justify-center {
  justify-content: center;
}

.fl-justify-end {
  justify-content: flex-end;
}

.fl-align-items-center {
  align-items: center;
}

.fl-align-items-start {
  align-items: flex-start;
}

.fl-align-items-baseline {
  align-items: baseline;
}

.fl-align-items-end {
  align-items: flex-end;
}



