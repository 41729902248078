

$font-stack:  "OpenSans", "Helvetica Neue", Helvetica, Arial, sans-serif;

$theme_color: #1ca8f1;
$theme_color_dark: #213745;
$theme_color_light: #D0E2EB;
$theme_color_light_light: #edf6fa;

$theme_color_0: #fff;
$theme_color_1: #edf6fa;
$theme_color_2: #D0E2EB;
$theme_color_3: #91d0f2;
$theme_color_4: #5fbbed;
$theme_color_5: #2AA7EC;
$theme_color_6: #2591cc;
$theme_color_7: #207fb3;
$theme_color_8: #0d5780;
$theme_color_9: #213745;
$theme_color_10: #555;
$theme_color_11: #949FA6;
$theme_color_12: #000;
$theme_color_13: #E2E2E2;
$theme_color_14: #ed1414;
$theme_color_15: #989797;
$theme_color_16: #ff0303;
$theme_color_17: #2a414d;
$theme_color_positive: #2ABC28;
$theme_color_negative: #E74C3C;
$theme_color_NA: #FFCC00;
$pale-grey: #eff5f9;
$dark-blue-grey: #203746;
$greyblue: #85a6bd;

$theme_alert_danger: rgba(235, 55, 55, 0.85);
$theme_alert_warning: rgba(235, 220, 89, 0.85);
$theme_alert_success: rgba(100, 210, 60, 0.85);
$theme_alert_info: rgba(3, 158, 239, 0.85);

$theme_text_color_0: #fff;
$theme_text_color_1: #999;
$theme_text_color_2: #555;
$theme_text_color_3: #000;
$theme_text_color_4: #ccc;
$theme_semitransparent_white: rgba(255, 255, 255, 0.95);

$text_size_tiny: 9px;
$text_size_small: 10px;
$text_size_normal: 13px;
$text_size_big: 15px;
$text_size_large: 17px;

$button_width: 120px;
$button_hover_color: $theme_color;
$button_radius: 5px;
$yes_button_background_color:#ec4d0f;
$no_button_background_color:#039eef;

$header_height: 60px;
$header_background_color: rgba(254,252,253,0.85);

$feature_list_hover_background_color: $theme_color_1;

$form_text_color: $theme_text_color_1;
$form_divider_background_color: #eef3f5;

$modal_height: 85vh;
$modal_height_sm: 20vh;
$modal_background_color: $theme_color_0;
$modal_tabs_background_color: $theme_color_1;
$modal_header_height: 43px;
$modal_header_site_margin: 3px;
$modal_padding: 10px;
$modal_footer_height: 43px;
$modal_corner_radius: 6px;
$modal_button_radius: 6px;
$modal_width: 90vw;
$modal_max_width: 960px;
//$modal_width_sm: 237px;
//$modal_width_sm: 24vw;
$modal_width_sm: 295px;
$modal_max_width_sm: 100%;
$modal_width_pbi: auto;
$modal_max_width_pbi: 100%;
$modal_width_lg: 85vw;
$modal_max_width_lg: 100%;
$modal_width_conf: 295px;
$modal_max_width_conf: 100%;
$modal_height_conf: 150px;
$modal_max_height_conf: 100%;
$modal_pos_top: 100px;
$modal_pos_left_right: 50px;

$tabs_header_color: $theme_color_11;
$tabs_header_text_color: $theme_text_color_2;
$tabs_ul_background_color: $theme_color_1;
$tabs_li_background_color: $theme_color_1;
$tabs_li_hover_background_color: $theme_color_0;

$toolbar_container_height: 100%;

$overlay_color_footer: $theme_color;
$overlay_footer_background_color: #e2eff6;
$overlay_arrow_background_color: #e2eff6;
$overlay_body_background_color: $theme_color;
$overlay_header_background_color: $theme_color_9;
$overlay_content_background_color: #d0e2ec;

$auth_width: 324px;
$input_width: 39px;
$input_height: 39px;
$form_element_height: 37px;
$confirm_button_width: 112px;

$color-white:#FFFFFF;
$button_inactive_grey: #bbc4c8;
$light_grey_input_field_inactive: #a7bdc9;
$dark_grey:#4c6876;
$tablano_blue :#039eef;
$red_error: #fd3e0b;
$green_correct: #1ac31d;
