@import "constants";

/*
	#Header-part
*/

.logo-container {
  width: 98px;
  background: rgba(3,158,239,.9) center url('../img/logo.png') no-repeat;
  margin-right: 17px;
}

.header {
  /**
   * The header "height" has always to by synchronized with .content "margin-top" part.
   */
  height: $header_height;
  background-color: $header_background_color;
  position: absolute;
  width: 100%;
  z-index: 9;
}

/**
 * shadow for the header. It is done by this way, because it is not possible to have
 * under this shadow open menu items, because it's part of it. So I create another virtual
 * header layer.
 */
.header--shadow {
  height: $header_height;
  width: 100%;
  background-color: transparent;
  box-shadow: 0 0 5px 3px rgba(161,161,161,0.5);
  position: absolute;
  z-index: 1;
  pointer-events: none;
}

.header__input-search {
  width: 300px;
}

.header *, .header *:hover,
.header__btn:focus {
  color: #A0A0A0;
}

.header .open > .dropdown-menu {
  background-color: $header_background_color;
  margin-top: 0;
  border-top: 4px solid $tablano_blue;
  left: auto;
  border-radius: 0;
  min-width: $button_width;
  width: auto;
}

.header .nav.user-nav {
  padding-left: 30px;
}

.header .nav.user-nav>.btn{
  &:hover {
    color: #646464;
  }
}

.nav.user-nav .ico--submenu_logout {
  color: #EC3333;
  &:hover {
    color: #F96363;
  }
}
.form-control-feedback{
  width: $input_width;
  height: $input_height;
}
.btn .badge {
  position: relative;
  top: 10px;
  right: -10px;
}