html, body {
  height: 100%;
  overflow: hidden;
}

.ol-control {
  display: none;
}

/*
	#Box-sizing
*/

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

/*
   flex--stretch class is used to fill gap between left alignment and
   right alignment divs in display: stretch
*/
.flex--stretch {
  flex-grow: 2;
}


.modal-body::-webkit-scrollbar-track,
.tab-content::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: transparent;
}

.modal-body::-webkit-scrollbar,
.tab-content::-webkit-scrollbar
{
  width: 6px;
  background-color: transparent;
}

.modal-body::-webkit-scrollbar-thumb,
.tab-content::-webkit-scrollbar-thumb
{
  background-color: $theme_color_10;
  border-radius: 5px;
}

.div-body::-webkit-scrollbar-track,
.tab-content::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: transparent;
}

.div-body::-webkit-scrollbar,
.tab-content::-webkit-scrollbar
{
  width: 6px;
  background-color: transparent;
}

.div-body::-webkit-scrollbar-thumb,
.tab-content::-webkit-scrollbar-thumb
{
  background-color: $theme_color_10;
  border-radius: 5px;
}


.w-72{
  width:72px !important;
}

.benutzer__name{
  width: 200px;
}
.benutzer__password{
  width: 150px;
  & button{
    &.modal-button-submit{
      font-style: normal;
      padding: 0 15px;
      &:disabled{
        background-color: #CDECFC;
        &:hover,&:focus{
          color: #fff;
        }
      }
      &:hover,&:focus{
        color: #fff;
      }
    }
  }
}
.benutzer__password__input{
  width: 250px;
  & input{
    background-color: #eee;
    border: thin solid #aaa;
    border-radius: 3px;
    color: #888;
    &.error{
      border: thin solid #ff0303 !important;
      box-shadow: 0 0 2px #E74C3C;
    }
    &.success{
      border: thin solid #7dD321 !important;
      box-shadow: 0 0 2px #7ED321;            
    }
  }
}

.pr-20{
  padding-right: 20px;
}

.align-right{
  text-align: right;
}