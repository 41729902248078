/* Base for label styling */
.custom__checkbox[type="checkbox"]:not(:checked),
.custom__checkbox[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
.custom__checkbox[type="checkbox"]:not(:checked) + label,
.custom__checkbox[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 1.95em;
  cursor: pointer;
}

/* checkbox aspect */
.custom__checkbox[type="checkbox"]:not(:checked) + label:before{
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 16px;
  height: 16px;
  background-color: $color-white;
  border: solid 1px $light_grey_input_field_inactive;
}
.custom__checkbox[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left: 0; top: 0;
  width: 16px;
  height: 16px;
  background-color: $color-white;
  border: solid 1px $dark_grey;
}
/* checked mark aspect */
.custom__checkbox[type="checkbox"]:not(:checked) + label:after,
.custom__checkbox[type="checkbox"]:checked + label:after {
  content: '✔';
  position: absolute;
  top: .1em; left: .1em;
  font-size: 1.1em;
  line-height: 0.8;
  color: $tablano_blue;
  transition: all .2s;
}
/* checked mark aspect changes */
.custom__checkbox[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.custom__checkbox[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
/* disabled checkbox */
.custom__checkbox[type="checkbox"]:disabled:not(:checked) + label:before,
.custom__checkbox[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}
.custom__checkbox[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
.custom__checkbox[type="checkbox"]:disabled + label {
  color: #aaa;
}
/* accessibility */
.custom__checkbox[type="checkbox"]:checked:focus + label:before,
.custom__checkbox[type="checkbox"]:not(:checked):focus + label:before {
  border: solid 1px $dark_grey;
}

/* hover style just for information */
label:hover:before {
  border: solid 1px $dark_grey;
}

