.alert-block{
  position: absolute;
  top: $header_height+20;
  right: 0;
  width: 35vw;
  z-index: 2000;
}
.alert{
  position: relative;
  z-index: 1;
  font-style: italic;
  font-size: $text_size_normal;
  padding-left: 40px;
  margin-right: 30px;
  span{
    color: $theme_color_0;
  }
  div > span:before{
    position:absolute;
    left:10px;
    top:10px;
  }
  button.close{
    background: transparent url('../img/gtl/alert-close.png') top left no-repeat;
    width: 17px;
    height: 17px;
  }
}

.alert-info{
  background-color: $theme_alert_info;
  div > span:before{
    content:url('../img/gtl/alert_info_icon.png');
  }
}
.alert-success{
  background-color: $theme_alert_success;
  div > span:before{
    content:url('../img/gtl/alert_success_icon.png');
  }
}
.alert-danger{
  background-color: $theme_alert_danger;
  div > span:before{
    content:url('../img/gtl/alert_danger_icon.png');
  }
}
.alert-warning{
  background-color: $theme_alert_warning;
  div > span:before{
    content:url('../img/gtl/alert_warning_icon.png');
  }
}

