@import "constants";
/**
    #feature-list
**/

table.feature-list {
  overflow: scroll;
}

.feature-list {
  width: 100%;
}

.feature-list__row {
  height: 35px;
  &:hover{
    background-color: $feature_list_hover_background_color;
  }
}

.feature-list__cell {
  border-bottom: 1px solid #D0D0D0;
  font-size: 12px;
  height: 45px;
  a{
    cursor: pointer;
  }
  
  &.like--icon--width{
    width: 43px;
  }
}
.feature-list__icon-cell{
  width: 43px;
}

.feature-list__cell--no-border {
  border-bottom: none;
}

.feature-list__cell--empty {
  /* We have extra empty cell before and after because on hover we have to covered 100% width of modal */
  width: 25px;
  display: table-cell;
}

.feature-list__cell--wide-last{
  width: 32px;
}

.feature-list__cell--narrow-last{
  width: 5px;
}

.feature-list__cell--delete-link{
  color:#f51f1f !important;
  font-weight: 700;
  &:hover{
    text-decoration: underline
  }
}
//.feature-list__cell{
  .text--detail {
    color: #aaa;
    padding-left:5px;
  }
  .object--title{
    font-size: $text_size_big+1;
    color: $theme_color_17;
    padding: 0 5px;
    width: 200px;
  }
  .ico {
    height: 100%;
  }
//}

.feature-list__cell_pointer {
  @extend .feature-list__cell;
  cursor:pointer;
}

/* This is icon of the feature */
.icon--feature,
  .feature-list__row .ico {
    width: 25px;
    margin: 0 5px;
    vertical-align: middle;
  }


  .feature-list__row *:focus {
    outline: none;
  }

.row_old {
  background-color: white;
  opacity: 0.4;
  td{
    color: $theme_text_color_4;
  }
  span.dringend{
    color: $theme_text_color_4;
    &:before{
      content: '';
      color: $theme_text_color_4;
      width: 13px;
      height: 13px;
      border: thin solid $theme_text_color_4;
      display: inline-block;
      background-color: $theme_text_color_4;
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .custom-checkbox{
    &>input[type="checkbox"] + label span {
      background:url("../img/gtl/overlay_checkbox_default-grey.png") left top no-repeat;
    }
    &>input[type="checkbox"]:checked + label span {
      background:url("../img/gtl/overlay_checkbox_default-grey.png") right top no-repeat;
    }
  }
  .ico-overlay_list_edit{
    background-image: url("../img/gtl/overlay_list_edit.png");
  }
  .ico-overlay_list_delete{
    background-image: url("../img/gtl/overlay_list_delete.png");
  }
  .ico-overlay_list_submit{
    background-image: url("../img/gtl/menu_task_active.png");
  }
}

