@import "constants";

$tabs_original_border_color: #ddd;

/**
    #tabs
**/

#pdfTestElement{
  background-color: #ffffff;
}

.nav-tabs {
  /* we want to have padding on ul tab according design */
  padding: 15px 47px 0 48px;
  display: flex;
  color: $tabs_header_text_color;
  background-color: $tabs_ul_background_color;
  flex-shrink: 0;
}

.nav-tabs > li > a,
.nav-tabs > li > a:hover {
  background-color: $tabs_li_background_color;
  border-color: $modal_tabs_background_color;
  border-bottom: $tabs_original_border_color;
  color: $tabs_header_text_color;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover {
  background-color: $tabs_li_hover_background_color;
  border: 1px solid $tabs_original_border_color;
  border-bottom-color: transparent;
  color: $tabs_header_text_color;
}

.nav-tabs > li > a {
  border-radius: 0;
  color: $tabs_header_text_color;
  font-size: $text_size_small;
  display: block;
  width: 100%;
}

.tab-content {
  background-color: $modal_background_color;
  overflow: auto;
  //to move slider a little bit lower.
  margin-top: 15px;
  padding-left: 48px;
  padding-right: 47px;
}

tab-heading[class*="ico-"] {
  width: auto;
  height: 22px;
  /* 4px because we need to center vertically the text, 25px to the left, because we need to have place for the icon*/
  padding: 4px 0 0 25px;
  background-position: left center;
//  background-size: auto 19px;
  display: inline-block;
  font-size: $text_size_normal;
  font-weight: 700;
}

.tab-heading-noicon {
  width: auto;
  height: 22px;
  /* 4px because we need to center vertically the text, 25px to the left, because we need to have place for the icon*/
  background-position: left center;
//  background-size: auto 19px;
  display: inline-block;
  font-size: $text_size_normal;
  font-weight: 700;
}