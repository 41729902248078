@import "constants";
@import "fonts";

/*
	#Forms
*/

.form-control {
  border-radius: 2px;
}

input {
  &::placeholder {
    color: #d0d0d0;
    font-weight: 200;
  }
  &:invalid{
    box-shadow:0 0 0 white;
  }
  &.input_icon{
    padding-left: 44px;
  }
  &.ng-invalid{
    border-color: red !important;
  }
  &:disabled{
    background-color: #F5F5F5 !important;
  }
}

input.ng-invalid + label span {
  background-color: rgba(255, 0, 0, 0.1) !important;
  border-radius: 10px !important;
}

input:disabled + label span {
  background-color: #F5F5F5 !important;
  border-radius: 10px !important;
}

select{
  &.input_icon{
    padding-left: 44px;
  }
  &::-ms-expand {
    display: none;
  }
  &.ng-invalid{
    border-color: red !important;
  }
  &:disabled{
    background-color: #F5F5F5 !important;
  }
}
textarea{
  &::placeholder {
    font-style: italic;
  }
  &.ng-invalid{
    border-color: red !important;
  }
  &:disabled{
    background-color: #F5F5F5 !important;
  }
}

dd {
    word-wrap: break-word;
}

/*
	#Buttons
*/

.button--fullwidth {
  width: 100%;
}

button {
  border: none;
  background-color: transparent;
  outline-offset: -7px;
  &:disabled{
    background-color: #989898 !important;
  }
}


.btn--fileUpload {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    line-height: 42px;
}

.btn--fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.btn--fileUpload span {
    @extend .text--theme-color;
    font-size: 13px;
    font-weight: 600;
}


  .button--nav {
    height: 100%;
    width: 100%;
    min-width: 140px;
    font-size: $text_size_small+2;
    font-weight: 700;
    border-bottom: 4px solid transparent;
    border-radius: 0;
    text-align: left;
    padding-left: 40px;
    &:hover {
      color: $button_hover_color;
      border-bottom: 4px solid $button_hover_color;
    }
  }
  .button--icon {
    @extend .button--nav;
    height: 60px;
    min-width: 90px;
    padding: 0;
    margin: 0;
    width: 90px;
    display: block;
  }
  .button--icon--last{
      margin-right: 30px;
    }
  .open {
    .button--nav{
      color: $button_hover_color;
    }
    & .dropdown-menu{
      .button--nav{
        color: #A0A0A0;
      }
    }
    .button--icon{
      color: $button_hover_color;
      // border-bottom: 4px solid $button_hover_color;
    }
  }

  .dropdown-menu{
    .button--nav{
      &:hover {
        color: $button_hover_color;
        border-bottom: 4px solid transparent;
      }
    }
  }

  .dropdown-menu__btn {
    text-align: left;
    padding: 6px 15px 6px 40px;
  }

  .dropdown-menu__btn-no_ico{
    padding: 6px 12px;
  }

.btn-tablano{
    height: $form_element_height;
    padding-left: 5px;
    padding-right: 5px;
    background-color: $theme_color_0;
    border: 1px solid $theme_color_13;
    border-radius: 2px;
    line-height: normal;
    font-size: $text_size_big+1;
    color: #999;
    text-align: left;
    &:hover,&:focus{
      background-color: $theme_color_0;
      border-color:$theme_color_13;
      color: #999;
    }
    & .caret{
      float: right;
      margin-top: 10px;
      margin-right: 4px;
    }
}

ul.select{
  display: block;
  width: auto;
}

.form__label {
  @extend .text--theme-color;
  @extend .text--caps;
  @extend .text--bold;
  @extend .ellipsis;
  font-size: $text_size_small;
  padding-top: 2px;
}

.form__divider {
  padding-bottom: 10px;
}

.form__field__divider {
  margin-bottom: 5px;
}

.form__divider--header {
  color: $theme_text_color_2;

  span {
    color: $theme_text_color_3;
  }
}

.form__group--sub {
  @extend .text--theme-color;
  font-size: $text_size_normal;
  color: $form_text_color;
  display: block;
  padding-top: 0px;
  padding-bottom: 0px;
}

.form__group--sub>* {
  display: inline;
  font-size: $text_size_normal;
  line-height: 20px;
}

//margin-top: -7px;

.form__label--sub {
}

.form__group {
  //:first-child {
  //  margin-top: -7px;
  //}
}

.form__group__label {
  @extend .form__label;
  padding-top: 10px;
}

.form__divider--header {
  background-color: $form_divider_background_color;
  padding: $modal_padding;
  margin-top: 25px;
}

*[class*="form__value--"]{
  padding: 1px 10px;
  color: #fff;
  border-radius: 10px;
  font-size: $text_size_small;
  cursor: default;
}

.form__sectio__col {
  padding: 0 15px 0 15px;
  &>label {
    padding-top: 10px;
    padding-bottom:5px;
  }
}

.form__sectio__row {
  padding: 15px 0 15px 0;
  &>label {
    padding-top: 10px;
    padding-bottom:5px;
  }
}

.form__section {
  padding: 15px 15px 15px 15px;
  &>label {
    padding-top: 10px;
    padding-bottom:5px;
  }
}

.form__section__nopadding {
  padding: 0px 0px 0px 0px;
  &>label {
    padding-top: 10px;
    padding-bottom:5px;
  }
}

.form__section__komm {
  @extend .form__section;
  padding-left: 50px;
}

.komm_title{
  font-size:$text_size_big;
}

.komm_message_text{
  color:$theme_color_17;
  font-weight: 700;
}

.komm_message_object{
  color: #039eef;
}

.komm_message{
  padding-top:15px;
  padding-bottom:5px;
}

.komm_source{
  color:$theme_text_color_1;
}

.div_disabled {
  pointer-events: none;
  opacity: 0.5;
}

.form__tlight-OK{
  @extend .form__group__label;
  color: $theme_color_positive;
  text-transform: uppercase;
  text-align: center;
  padding-left:5px;
  padding-right:5px;
  &:before{
    content: '';
    color: #ed1414;
    width: 13px;
    height: 13px;
    border: thin solid $theme_color_positive;
    display: inline-block;
    background-color: $theme_color_positive;
    border-radius: 50%;
  }
}

.form__tlight-ERROR{
  @extend .form__group__label;
  color: $theme_color_negative;
  text-transform: uppercase;
  text-align: center;
  padding-left:5px;
  padding-right:5px;
  &:before{
    content: '';
    color: #ed1414;
    width: 13px;
    height: 13px;
    border: thin solid $theme_color_negative;
    display: inline-block;
    background-color: $theme_color_negative;
    border-radius: 50%;
  }
}

.form__tlight-NA{
  @extend .form__group__label;
  color: $theme_color_NA;
  text-transform: uppercase;
  text-align: center;
  padding-left:5px;
  padding-right:5px;
  &:before{
    content: '';
    color: #ed1414;
    width: 13px;
    height: 13px;
    border: thin solid $theme_color_NA;
    display: inline-block;
    background-color: $theme_color_NA;
    border-radius: 50%;
  }
}

.form__tlight-ERRORK{}

.form__value--OK {
  background-color: $theme_color_positive;
}

.form__value--ERROR {
  background-color: $theme_color_negative;
}

.form__value--NA {
  background-color: $theme_color_NA;
}

.status-FH {
	color:#DB5D00;
}

.status-OK {
	color:#04BB00;
}

input, select, textarea {
  height: $form_element_height;
  padding-left: 5px;
  padding-right: 5px;
  background-color: $theme_color_0;
  border: 1px solid $theme_color_13;
  border-radius: 2px;
  line-height: normal;
  font-size: $text_size_big+1;
  // Firefox size fix
  min-width: 0px;
  width: 100%;
}

textarea{
  height: 58px;
  resize: none;
}


select {
  -webkit-appearance: inherit;
  -moz-appearance: inherit;
  background-image: url("../img/gtl/header_user_account_dropdown.png");
  background-repeat: no-repeat;
  background-position: right 8px center;
  //down arrow is on the right side, so we need to specify padding alittle bit different
  padding-right: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.form-content {
  background-color: $modal_background_color;
  //to move slider a little bit lower.
  margin-top: 45px;
  padding-left: 45px;
  padding-right: 45px;
}

.margin-top{
  margin-top: 20px;
}
.no-right-padding{
  padding-right: 0;
}

.permission_title{
  font-size: $text_size_big+1;
  font-weight: 600;
  color: #039eef
}

.custom-checkbox-default{
  padding: 15px 0;
  font-size: $text_size_small+2;
  &>label{
    color: $theme_text_color_1;
    text-transform: none;
    &.big-format{
      font-size: 16px;
      line-height: 18px;
    }
  }
  &>input[type="checkbox"] {
    display:none;
  }
  &>input[type="checkbox"] + label span {
    display:inline-block;
    width:22px;
    height:21px;
    margin:-1px 14px 0 0;
    vertical-align:middle;
    background:url("../img/gtl/overlay_checkbox_grey.png") left top no-repeat;
    cursor:pointer;
  }
  &>input[type="checkbox"]:checked + label span {
    background:url("../img/gtl/overlay_checkbox_grey.png") -22px top no-repeat;
  }
}

.custom_radio{
  &>input[type="radio"] {
    display:none;
  }
  &>input[type="radio"] + label span{
    font-size: $text_size_small+2;
    display: inline-block;
    color: $theme_text_color_1;
    text-transform: none;
    padding-left: 30px;
    line-height:21px;
    margin:-1px 30px 0 0;
    background:url("../img/gtl/overlay_editor_radiobutton.png") left center no-repeat;
    cursor:pointer;
  }
}

.custom_radio_color{
    @extend .custom_radio;
    padding-top: 10px;
    padding-bottom: 5px;
    line-height: 21px;
    &>input[id$='OK']:checked + label span{
    color:#04BB00;
    background:url("../img/gtl/overlay_editor_radiobutton_active_OK.png") left center no-repeat;
  }
    &>input[id$='FH']:checked + label span{
    color:#DB5D00;
    background:url("../img/gtl/overlay_editor_radiobutton_active_FH.png") left center no-repeat;
  }
    &>input[id$='NV']:checked + label span{
    color:#FFCC00;
    background:url("../img/gtl/overlay_editor_radiobutton_active_NV.png") left center no-repeat;
  }
    &>input[id$='BW']:checked + label span{
    background:url("../img/gtl/overlay_editor_radiobutton_active.png") left center no-repeat;
  }
}

.custom_radio_bw{
    @extend .custom_radio;
    &>input[type="radio"]:checked + label span{
    background:url("../img/gtl/overlay_editor_radiobutton_active.png") left center no-repeat;
  }
}

.custom_radio_label{
  &>label {
    padding-top: 11px;
    padding-bottom:6px;
  }
}

.custom-checkbox-radio{
  &>input[type="checkbox"] {
    display:none;
  }
  &>input[type="checkbox"] + label span {
    font-size: $text_size_small+2;
    display: inline-block;
    color: $theme_text_color_1;
    text-transform: none;
    padding-left: 30px;
    line-height:21px;
    margin:-1px 4px 0 0;
    background:url("../img/gtl/overlay_editor_radiobutton.png") left center no-repeat;
    cursor:pointer;
  }
  &>input[type="checkbox"]:checked + label span {
    background:url("../img/gtl/overlay_editor_radiobutton_active.png") left center no-repeat;
  }
}

.custom-checkbox-red{
  display: inline-block;
  padding: 15px 0;
  &>label{
    color: $theme_color_14;
    text-transform: uppercase;
  }
  &>input[type="checkbox"] {
    display:none;
  }
  &>input[type="checkbox"] + label span {
    display:inline-block;
    width:22px;
    height:21px;
    margin:-1px 4px 0 0;
    vertical-align:middle;
    background:url("../img/gtl/overlay_checkbox_red.png") left top no-repeat;
    cursor:pointer;
  }
  &>input[type="checkbox"]:checked + label span {
    background:url("../img/gtl/overlay_checkbox_red.png") -22px top no-repeat;
  }
}

.custom-checkbox{
  padding: 20px 0 10px;
  &>label{
    font-size: $text_size_normal+1;
    font-weight: 600;
    color: $theme_color_10;
    text-transform: capitalize;
  }
  &>input[type="checkbox"] {
    display:none;
  }
  &>input[type="checkbox"] + label span {
    display:inline-block;
    width:16px;
    height:16px;
    margin:-1px 8px 0 0;
    vertical-align:middle;
    background:url("../img/gtl/overlay_checkbox_default.png") left top no-repeat;
    cursor:pointer;
  }
  &>input[type="checkbox"]:checked + label span {
    background:url("../img/gtl/overlay_checkbox_default.png") -16px top no-repeat;
  }
}

.groups-box{
  margin: 10px 0 0;
  padding: 6px 14px 14px 14px;
  border: 1px solid $theme_color_13;
}

.show-hide-checkbox-default{
  display: inline-block;
  padding: 15px 0;
  font-size: $text_size_small+2;
  &>label{
    color: $theme_text_color_1;
    text-transform: none;
    width: 100%;
  }
  &>input[type="checkbox"] {
    display:none;
  }
  &>input[type="checkbox"] + label span {
    display:inline-block;
    width:17px;
    height:16px;
    margin:-1px 4px 0 0;
    vertical-align:middle;
    background:url("../img/gtl/show-hide_checkbox_default.png") left top no-repeat;
    cursor:pointer;
    float: right;
  }
  &>input[type="checkbox"]:checked + label span {
    background:url("../img/gtl/show-hide_checkbox_default.png") -17px top no-repeat;
  }
  &>input[type="checkbox"]:checked + label{
    text-decoration: line-through;
  }
//  &>input[type="checkbox"][disabled]:checked + label span{
//    cursor: pointer;
//  }
  &>input[type="checkbox"][disabled] + label span {
    cursor: not-allowed;
  }
}

.custom-checkbox-row{
  display: block;
  padding: 0px;
}

.custom-checkbox-center{
  padding: 10px 0 10px;
}

.hidden_radio{
  display: inline-block;
  &>input[type="radio"] {
    display:none;
  }
  &>input[type="radio"] + label{
    font-weight: 700;
    font-style: italic;
  }
  &>input[type="radio"]:checked + label{
    color:$theme_color_5;
  }
  & input{
    width: 100%;
  }
}

  .search-it{
    background-image: url("../img/gtl/header_search.png");
    background-repeat: no-repeat;
    background-position: center;
    pointer-events: none;
    position: none;
}
  .clear-it{
    background-image: url("../img/gtl/header_clear.png");
    background-repeat: no-repeat;
    background-position: center;
    margin-right: 20px;
    pointer-events: auto;
    cursor: pointer;
}
.search-list{
  width: 100%;
  line-height: 25px;
  text-align: right;
  background-color: #ffffff;
  box-shadow: 0 0 5px 3px rgba(161, 161, 161, 0.5);
  li{
    cursor: pointer;
    padding: 5px 10px;
    font-weight: 700;
    &:hover{
      color: $theme_color_5;
    }
  }
}

.kvs-table td {
  text-align: center;
  height: 64px;
  width: 81px;
  max-width: 81px;
  font-size: 12px;
  overflow: hidden;
  box-sizing: border-box;
  border-right: 1px solid;
  border-bottom: 1px solid;
  border-color: #ffffff;
  background-color: #ebf6f8;
}
.kvs-table tr:first-child {
  font-weight: bold;
}
.kvs-table tr:first-child td:first-child {
  font-size: 12px;
  font-weight: normal;
}
.kvs-table tr:first-child td {
  font-size: $text_size_large;
  background-color: #ffcc00;
}
.kvs-table tr td:first-child {
  background-color: #000000;
  color: #ffffff;
  border-bottom: none;
  font-weight: normal;
}
.kvs-table select {
  width: 71px;
  height: 54px;
  border: 0px;
  padding-right: 5px;
  font-size: 12px;
  background: transparent;
  -webkit-appearance: none;
  text-align: center;
  text-overflow: ellipsis;
}
.kvs-table button {
  width: 81px;
  height: 64px;
  padding: 0px;
  margin: 0px;
  font-size: $text_size_large;
  background-color: $theme_color;
  color: $theme_text_color_0;
}
.kvs-table input {
  height: 54px;
  width: 71px;
  background-color: #EBF6F8;
  -webkit-appearance: none;
  -webkit-border-radius:0;
  border:0;
  font-size: 12px;
}
.kvs-table input:focus{
  background-color: #FFFFFF;
}

.export__box{
  padding: 0;
  line-height: 19px;
  width: 140px;
  li{
    padding: 10px 17px;
    border-bottom: 1px solid #e4e4e4;
  }
}

.export__name{
  font-size: 13px;
  color:#808080;
}

.export__date{
  font-size: 13px;
  color: #808080;
}

.export__download{
  font-size: 13px;
  color: #00aff3;
  padding: 5px 0 0;
  .export__waiting{
    color:#a8a8a8;
  }
  a{
    color: #00aff3;
    text-decoration: underline;
    padding: 0;
    &:hover{
        text-decoration: none;
    }
  }
}

.tooltip.customClass .tooltip-inner {
  color: #555;
  background-color: #edf6fa;
  box-shadow: 0 3px 6px rgba(0,0,0,.175);
  text-align: left;
  max-width: 400px;
  padding: 10px;
}

.tooltip.customClass .tooltip-arrow {
  display: none;
}

.tooltip.customClass strong{
  font-weight: 700;
  font-size: 100%;
}

.timepicker .form-control {
    border-radius: 2px !important;
    border: 1px solid #E2E2E2 !important;
    font-size: 16px !important;
    color: #999 !important;
    height: 37px !important;
}

.table-column {
    flex: 0 0 auto;
    width: 128px;
    border-left: 1px solid;
    border-color: $theme_color_0;
}

.table-column:first-child {
    border-left: 0;
}

.table-cell {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    height: 64px;
    overflow: hidden;
    color: $theme_text_color_3;
    background-color: $form_divider_background_color;
    border-top: 1px solid;
    border-color: $theme_color_0;
}

.table-cell:first-child {
    border-top: 0;
}

.table-cell-header {
    color: $theme_text_color_0;
    background-color: $theme_color_12;
    border-color: $theme_color_15;
}

.table-cell-caption {
    width: 100%;
    max-height: 100%;
    padding: 5px;
    word-break: break-word;
    font-size: 12px;
    font-weight: normal;
    text-align: center;
}

.table-cell-value {
    width: 100%;
    max-height: 100%;
    padding: 5px;
    word-break: break-word;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
}

.table-cell select {
    width: 100%;
    height: 100%;
    padding: 5px;
    white-space: pre-line;
    word-break: break-word;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    background-color: $form_divider_background_color;
    border: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}

.table-cell input {
    width: 100%;
    height: 100%;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    background-color: $form_divider_background_color;
    border: 0;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}

.table-cell textarea {
    width: 100%;
    height: 100%;
    padding: 5px;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    background-color: $form_divider_background_color;
    border: 0;
    resize: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}

.table-cell button {
    width: 100%;
    height: 100%;
    padding: 5px;
    font-size: 14px;
    font-weight: bold;
    color: $theme_text_color_0;
    background-color: $theme_color;
    &:hover{
        background-color: $theme_color_dark;
    }
}
