@import "constants";

/**
 * Toolbars are placeholders which contains 2 elements. One is toolbar__left and second is toolbar__right
 * All children of direct children elements are displayed as 'inline-block':
 *
 */

.toolbar {
  &__left {
    float: left;
    height: $toolbar_container_height;
  }

  &__right {
    float: right;
    height: $toolbar_container_height;

    &--margin {
      margin-right: 2px;
    }
  }

  &__left > *,
  &__right > * {
    float: left;
    height: $toolbar_container_height;
  }

  & input {
    height: $input_height + 1;
  }
  ak-counter {
    position: relative;
    display: block;
    pointer-events: none;
    & span {
      position: absolute;
      bottom: 1px;
      &:nth-child(1) {
        left: 0px;
      }
      &:nth-child(2) {
        left: 50%;
      }
    }
  }
  .common_items {
    width: 360px !important;
    font-family: Lato;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6d6d6d;
    .truncate {
      width: 130px;
      overflow:hidden; 
      white-space:nowrap; 
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: middle;
      color: #6d6d6d;
      &.active{
        color:#a7a7a7;
      }
    }
    li{
      display: flex;
      flex-wrap: wrap;
      padding: 20px 17px 17px 17px;
      border-bottom: 1px solid #e4e4e4;
      &:last-child{
        border-bottom: 0;
      }
      .half{
        flex: 0 0 50%;
        font-size: 14px;
        height: 23px;
      }
    }
    .checkbox-unchecked {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: -1px 8px 0px 0px;
      vertical-align: middle;
      background: url("../img/gtl/overlay_checkbox_default-grey.png") left top
        no-repeat;
      cursor: pointer;
    }
    .checkbox-checked {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: -1px 8px 0px 0px;
      vertical-align: middle;
      background: url("../img/gtl/overlay_checkbox_default-grey.png") right top
        no-repeat;
      cursor: pointer;
    }
  }
  .slider_wrapper{
    display: flex;
    flex-wrap: wrap;
    .opacity_value{
      flex: 0 0 30%;
      text-align: right
    }
    &.active-layer{
      color: $tablano_blue;
      & > .opacity_slider{
        .rz-selection{
          background-color: $tablano_blue !important;
        }
      }
    }
    .opacity_slider {
      flex: 0 0 70%;
      .rzslider {
        height: 2px;
        margin: 20px 0 35px 0;
        .rz-pointer {
          top: -19px;
          width: 19px;
          height: 19px;
          background-color: #cacaca;
          &:after {
            top: 1px;
            left: 1px;
            width: 17px;
            height: 17px;
            background: #ffffff;
            -webkit-border-radius: 10px;
            -moz-border-radius: 10px;
            border-radius: 10px;
          }
        }
        .rz-bar-wrapper {
          height: 32px;
          padding-top: 6px;
          .rz-bar{
            color:#d3d3d3;
            height:2px;
          }
        }
        .rz-selection{
          background-color: #7c7c7c;
        }
      }
    }
  }
}

/**
 * There is problem with vertical alignment of floated elements, this is hack for make it working
 *
 * Be aware we have to manually change the margin-top,
 */
.toolbar__right > * > .vertical-alignment--manual {
  margin-top: 10px;
}
