/*
    #Icons

    $icon_path: define the path to icons
    @mixin icon($file_name): generates the background for icon
 */

$icon_path: "../img/gtl/";

.icon-flipped {
  transform: scaleX(-1);
}

[class^="ico-"], [class*="ico-"] {
  background-position: center;
  background-repeat: no-repeat;
  /* Currently it is size of modal_header height. */
  width: $modal_header_height;
  height: $modal_header_height;
  padding: 0 15px;
  display: inline-block;
}

.ico-popup{
  width: 20px;
  height: 20px;
  padding: 15px;
}

@mixin icon($file_name) {
  background-image: url($icon_path + $file_name);
}

.ico-overlay_header_list {
  @include icon("overlay_header_list.png");
}

.ico-overlay_header_close {
  @include icon("overlay_header_close.png");
  &:hover, &:active {
    @include icon("overlay_header_close_active.png");
  }
}
.ico-overlay_header_dark_close {
  @include icon("overlay_header_dark_close.png");
  &:hover, &:active {
    @include icon("overlay_header_dark_close_active.png");
  }
}

.ico-overlay_header_favorite {
  @include icon("overlay_header_favorite.png");
  &:hover, &:active {
    @include icon("overlay_header_favorite_active.png");
  }
}

.ico-overlay_header_edit {
  @include icon("overlay_header_edit.png");
  &:hover, &:active {
    @include icon("overlay_header_edit_active.png");
  }
}

.ico-water {
  @include icon("overlay_header_favorite.png");
}

.ico-comments {
  @include icon("tablano_cms_icon_comment.png");
}

.ico-history {
  @include icon("tablano_cms_icon_history.png");
}

.ico-export {
  @include icon("tablano_cms_icon_download.png");
  &:hover, &:active {
    @include icon("tablano_cms_icon_download_active.png");
    outline: none;
  }
}

.ico-layers {
  @include icon("tablano_cms_icon_layers.png");
  &:hover, &:active {
    @include icon("tablano_cms_icon_layers_active.png");
    outline: none;
  }
}

.ico-overlay_list_edit {
  @include icon("overlay_list_edit.png");
  &:hover, &:active {
    @include icon("overlay_list_edit_active.png");
    outline: none;
  }
}

.ico-overlay_list_delete {
  @include icon("overlay_list_delete.png");
  &:hover, &:active {
    @include icon("overlay_list_delete_active.png");
    outline: none;
  }
}
.ico-overlay_list_submit{
  background-image: url("../img/gtl/menu_task.png");
  &:hover, &:active {
    background-image: url("../img/gtl/menu_task_active.png");
    outline: none;
  }
}
.ico-overlay_list_task {
  @include icon("overlay_list_task.png");
  &:hover, &:active {
    @include icon("overlay_list_task_active.png");
    outline: none;
  }
}
.ico-overlay_list_position{
  @include icon("overlay_list_position.png");
  &:hover, &:active {
    @include icon("overlay_list_position_active.png");
    outline: none;
  }
}
.ico-overlay_list_repair {
  @include icon("overlay_list_repair.png");
  &:hover, &:active {
    @include icon("overlay_list_repair_active.png");
    outline: none;
  }
}
.ico-overlay_popup_aim {
  @include icon("overlay_popup-aim.png");
}
.ico-overlay_popup_delete {
  @include icon("overlay_popup-delete.png");
}
.ico-overlay_popup_task {
  @include icon("overlay_popup-task.png");
}
.ico-overlay_list_has_task{
  @include icon("overlay_list_has_task.png");
}

.ico-overlay_files_delete {
  @include icon("overlay_files_delete.png");
  &:hover, &:active {
    @include icon("overlay_files_delete_active.png");
    outline: none;
  }
}
.ico-overlay_files_confirm {
  @include icon("menu_task.png");
  &:hover, &:active {
    @include icon("menu_task_active.png");
    outline: none;
  }
}
  .ico--menu_object{
    @include icon("menu_poi.png");
    background-repeat: no-repeat;
    background-position: left 14px center;
    &:hover, &:active {
      @include icon("menu_poi_active.png");
      outline: none;
    }
  }
  .ico--menu_task{
    @include icon("menu_task.png");
    background-repeat: no-repeat;
    background-position: left 14px center;
    &:hover, &:active {
      @include icon("menu_task_active.png");
      outline: none;
    }
  }
  .ico--menu_user{
    background-image: url('../img/icons/glyphicons-4-user.png');
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: left 14px center;
    &:hover, &:active {
      background-image: url('../img/icons/glyphicons-4-user.png');
      outline: none;
    }
  }
  .ico--edit_user{
    @include icon("edit_user.png");
    background-repeat: no-repeat;
    background-position: left 14px center;
    &:hover, &:active {
      @include icon("edit_user_active.png");
      outline: none;
    }
  }
  .open{
    & .ico--menu_task{
      @include icon("menu_task_active.png");
      background-repeat: no-repeat;
      background-position: left 14px center;
      color: #2AA7EC;
      border-bottom: 4px solid #2AA7EC;
    }
    & .ico--menu_object{
      @include icon("menu_poi_active.png");
      background-repeat: no-repeat;
      background-position: left 14px center;
      color: #2AA7EC;
      border-bottom: 4px solid #2AA7EC;
    }
    .ico-export{
      @include icon("tablano_cms_icon_download_active.png");
      outline: none;
    }
    .ico-layers{
      @include icon("tablano_cms_icon_layers_active.png");
      outline: none;
    }
    & .ico--edit_user{
      @include icon("edit_user_active.png");
      background-repeat: no-repeat;
      background-position: left 14px center;
      color: #2AA7EC;
      border-bottom: 4px solid #2AA7EC;
    }
  }
  .ico--submenu_all{
    @include icon("submenu_list.png");
    background-repeat: no-repeat;
    background-position: left 21px center;
    &:hover, &:active {
      @include icon("submenu_list_active.png");
      outline: none;
    }
  }
  .ico--submenu_new_item{
    @include icon("submenu_add.png");
    background-repeat: no-repeat;
    background-position: left 21px center;
    &:hover, &:active {
      @include icon("submenu_add_active.png");
      outline: none;
    }
  }


.ico--submenu_favorite {
  @include icon("submenu_favorite.png");
}

.ico--submenu_favorite_active {
  @include icon("submenu_favorite_active.png");
}

.ico--overlay_header_favorite {
  @include icon("overlay_header_favorite.png");
}

.ico--overlay_header_favorite_active {
  @include icon("overlay_header_favorite_active.png");

}

.ico--submenu_logout {
  @include icon("submenu_logout.png");
  background-repeat: no-repeat;
  background-position: left 21px center;
  background-size: 11px;
  &:hover, &:active {
    @include icon("submenu_logout_active.png");
  }
}

.ico--header_user_account,
.btn.ico--header_user_account {
  @include icon("header_user_account.png");
  background-repeat: no-repeat;
  background-position: left 14px center;
  &:hover, &:active {
    @include icon("header_user_account_active.png");
  }
}
.open{
  .ico--header_user_account,
  .btn.ico--header_user_account {
    @include icon("header_user_account.png");
    background-repeat: no-repeat;
    background-position: left 14px center;
    color: #A0A0A0 !important;
    &:hover, &:active {
      @include icon("header_user_account_active.png");
    }
  }
  .ico--submenu_logout {
    color: #EC3333 !important;
  }
}

  /*.ico-overlay_list_edit:focus,*/
  /* We need to make active icons when user hovers over table row as well */
.feature-list__row {
  &:hover .ico-overlay_list_edit,
  &:active .ico-overlay_list_edit{
    background-image: url("../img/gtl/overlay_list_edit_active.png");
    outline: none;
  }
  &:hover .ico-overlay_list_delete,
  &:active .ico-overlay_list_delete{
    background-image: url("../img/gtl/overlay_list_delete_active.png");
    outline: none;
  }
  &:hover .ico-overlay_list_task,
  &:active .ico-overlay_list_task{
    background-image: url("../img/gtl/overlay_list_task_active.png");
    outline: none;
  }
  &:hover .ico-overlay_list_position,
  &:active .ico-overlay_list_position{
    background-image: url("../img/gtl/overlay_list_position_active.png");
    outline: none;
  }
}

.row_active{
  background-color: #eef3f5;
  .ico-overlay_list_edit{
    background-image: url("../img/gtl/overlay_list_edit_active.png");
    outline: none;
  }
  .ico-overlay_list_delete{
    background-image: url("../img/gtl/overlay_list_delete_active.png");
    outline: none;
  }

}


.ico-overlay_header_back {
  @include icon("overlay_header_back.png");
}

btn.ico-overlay_header_back:hover,
.btn.ico-overlay_header_back:hover,
btn.ico-overlay_header_back:active,
.btn.ico-overlay_header_back:active{
  @include icon("overlay_header_back_active.png");
}

.ico-overlay_tab_stammdaten {
  @include icon("overlay_tab_stammdaten.png");
}

.ico-overlay_tab_editor {
  @include icon("overlay_tab_editor.png");
}

.ico-overlay_tab_files {
  @include icon("overlay_tab_files.png");
}
